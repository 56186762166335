
import { defineComponent } from "vue";
import Card from "primevue/card";
import Divider from "primevue/divider";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import CustomAccordion from "@/components/UI/CustomAccordion.vue";
import AccordionTab from "primevue/accordiontab";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "Export",
  components: {
    Card,
    Divider,
    InputText,
    Checkbox,
    Button,
    Dropdown,
    CustomAccordion,
    AccordionTab,
  },

  computed: {
    ...mapGetters({
      getActiveOrderTab: "salesInquiry/getActiveOrderTab",
    }),
  },
  data: () => ({
    currentId: "",
    currentOrder: {} as any,
    endUserInfo: {
      country: "Country 1",
      user: "End user name",
      endUserStatementAttached: "Y",
      listToCheck: [
        {
          id: 1,
          list: "List 1",
          checked: "",
        },
        {
          id: 2,
          list: "List 2",
          checked: "Y",
        },
        {
          id: 3,
          list: "List 3",
          checked: "Y",
        },
      ],
      partEndUseOptions: [
        { name: "End use 1", code: "USE1" },
        { name: "End use 2", code: "USE2" },
      ],
      partsInfo: [
        {
          li: "1",
          partNumber: "123456",
          endUse: "USE1",
        },
        {
          li: "2",
          partNumber: "234567",
          endUse: "USE1",
        },
        {
          li: "3",
          partNumber: "345678",
          endUse: "USE1",
        },
      ],
    },
  }),

  created() {
    if (this.$attrs.orderId) {
      this.currentId = this.$attrs.orderId as string;
      this.currentOrder = this.getActiveOrderTab(this.currentId);
    }
  },
  mounted() {
    if (this.$attrs.orderId) {
      this.currentId = this.$attrs.orderId as string;
      this.currentOrder = this.getActiveOrderTab(this.currentId);
    }
  },
});
